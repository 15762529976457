/**
 * Sezóna 2023/2024
 */
import React from 'react'

import { DefaultPageProps } from '../../types'
import Layout from '../../components/layout'

const Zapis = ({ location }: DefaultPageProps) => {
  return (
    <Layout location={location} customClass="enrollment">
      <header>
        <h1>Předzápis na cvičební rok 2023/2024</h1>
      </header>
      <section className="page-content">
        <p>
          Online předzápis <strong>byl již ukončen.</strong>
          <br />
        </p>
        <p>
          Stále se však můžete přihlásit během standardního zápisu, který bude
          probíhat online na našich stránkách od 1. srpna.
        </p>
      </section>
    </Layout>
  )
}

export default Zapis
